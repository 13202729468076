import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#EB3995',
        secondary: '#131448',
        accent: '#131448',
      },
    },
  },
  icons: {
    iconfont: 'mdiSvg',
  },
});